import React from "react"
import Layout from "../layout";
import path from "path";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { navigate } from "gatsby";

class CollectionTemplate extends React.Component {
   constructor(props) {
      super(props);

      const nameToFluidPhoto = {};
      const nameToFixedPhoto = {};

      props.data.allMarkdownRemark.edges.forEach((edge) => {
         if (edge.node.fields) {
            const name = path.basename(edge.node.fileAbsolutePath)
            nameToFluidPhoto[name] = edge.node.fields.image.childImageSharp.fluid;
            nameToFixedPhoto[name] = edge.node.fields.image.childImageSharp.fixed;
         }
      });

      this.state = {
         nameToFluidPhoto,
         nameToFixedPhoto
      };
   }

   getGridListCols(){
      if (isWidthUp('xl', this.props.width)) {
         return 4;
      }

      if (isWidthUp('lg', this.props.width)) {
         return 3;
      }

      if (isWidthUp('md', this.props.width)) {
         return 2;
      }

      return 1;
   }

   collectionPhotoClicked = (image) => () => {
      navigate(`/gallery/${this.props.pageContext.name}/${image}`);
   };

   render() {
      return (
         <Layout galleryLinksOpen={true}>
            <GridList cellHeight={250} cols={this.getGridListCols()} style={{width: "100%"}}>
               {this.props.pageContext.order && this.props.pageContext.order.map(image => (
                  <GridListTile key={image}
                                cols={1}
                                style={{padding:"5px"}}
                                className={'collection-photo'}
                                onClick={this.collectionPhotoClicked(image).bind(this)}>
                     <img src={this.state.nameToFixedPhoto[image].src} alt={image}/>
                  </GridListTile>
               ))}
            </GridList>
         </Layout>
      )
   }
}

export default withWidth()(CollectionTemplate);
