import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CollectionTemplate from "./collection-template";

export default (props) => (
   <StaticQuery
      query={graphql`
      query CollectionTemplateWrapper {
        allMarkdownRemark {
           edges {
              node {
                 fileAbsolutePath
                 frontmatter {
                     title
                     file
                   }
                   fields {
                        image {
                         childImageSharp {
                           fixed(width: 700, height: 500, quality: 100) {
                              ...GatsbyImageSharpFixed
                           }
                           fluid(maxWidth: 800, quality: 100) {
                           # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                              ...GatsbyImageSharpFluid
                           }
                         }
                       }
                   }
                
              }
           }
        }   
      }
    `}
      render={data => (
         <CollectionTemplate data={data} pageContext={props.pageContext}/>
      )}
   />
)



